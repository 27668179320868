import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { searchMysteryTags } from '../actions';

export default function useTagSearch() {
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();

  function searchTags(tag_ids, ocean_regions) {
    dispatch(searchMysteryTags(location.pathname, { tag_ids, ocean_regions }))
      .then((res) => {
        setData(res);
      })
      .catch((error) => {
        setData({ error });
      });
  }

  return { data, setData, searchTags };
}
