import { useState } from 'react';
import OceanRegionsSelector from './OceanRegionsSelector';
import { read, utils } from 'xlsx';
import CustomFileInput from './CustomFileInput';
import { Form } from 'semantic-ui-react';

export function FileSelector({
  regionsValue,
  regionSelectHandler,
  selectAllRegionsHandler,
  setTagIds,
  tagIds,
  tagSearchHandler,
  setTaggerNameHandler,
  setTagOwnerPIHandler,
  setTagOwnerOrgHandler,
  setTagTypeHandler,
  setSerialNumbers,
  setTagLife,
}) {
  const [sheetNames, setSheetNames] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [sheets, setSheets] = useState([]);
  const [activeSheetIndex, setActiveSheetIndex] = useState(null);
  const [selectedColumnIndex, setSelectedColumnIndex] = useState(null);
  const [isEmptySheet, setIsEmptySheet] = useState(false);

  function processFile(file) {
    const reader = new FileReader();
    reader.onload = handleFileLoad;
    reader.readAsBinaryString(file);
  }

  function handleFileLoad(event) {
    const binaryData = event.target.result;
    const workbook = read(binaryData, { type: 'binary' });
    const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = utils.sheet_to_json(firstSheet, { defval: '' });

    if (jsonData.length > 0) {
      updateSheetData(0, workbook, jsonData); // Load the first sheet by default
    } else {
      resetSheetState();
    }
  }

  function handleFileChange(event) {
    const file = event.target.files[0];
    if (file) processFile(file);
  }

  function handleSheetChange(event) {
    const newIndex = Number(event.target.value);
    const activeSheet = sheets.Sheets[sheets.SheetNames[newIndex]];
    const jsonData = utils.sheet_to_json(activeSheet, { defval: '' });
    updateSheetData(newIndex, sheets, jsonData);
  }

  function handleColumnChange(event) {
    const columnIndex = Number(event.target.value);
    updateColumnData(columnIndex);
  }

  function extractColumnValues(data, columnIndex) {
    return data.map((row) => row[Object.keys(row)[columnIndex]]).join('\n');
  }

  function updateSheetData(sheetIndex, workbook, jsonData) {
    const columnHeaders = Object.keys(jsonData[0]);
    const tagIdIndex = columnHeaders.findIndex((header) =>
      header.toLowerCase().includes('vue tag id'),
    );

    const defaultColumnIndex = tagIdIndex !== -1 ? tagIdIndex : 0;
    const columnValues = extractColumnValues(jsonData, defaultColumnIndex);

    const researcherIndex = columnHeaders.findIndex((header) =>
      header.toLowerCase().includes('researcher'),
    );
    if (researcherIndex !== -1) {
      const researcherValue = jsonData[0][columnHeaders[researcherIndex]];
      setTagOwnerPIHandler(researcherValue);
      setTaggerNameHandler(researcherValue);
    }

    const tagOwnerOrgIndex = columnHeaders.findIndex((header) =>
      header.toLowerCase().includes('customer'),
    );
    if (tagOwnerOrgIndex !== -1) {
      const tagOwnerOrgValue = jsonData[0][columnHeaders[tagOwnerOrgIndex]];
      setTagOwnerOrgHandler(tagOwnerOrgValue);
    }
    const tagFamilyIndex = columnHeaders.indexOf('Tag Family');
    if (tagFamilyIndex >= 0) {
      let tagTypes = '';
      for (let i = 0; i < jsonData.length; i++) {
        tagTypes += jsonData[i]['Tag Family'];
        if (i < jsonData.length - 1) tagTypes += '\n';
      }
      setTagTypeHandler(tagTypes);
    }
    const serialNumberIndex = columnHeaders.indexOf('Serial No. ');
    if (serialNumberIndex >= 0) {
      let serialNumbers = '';
      for (let i = 0; i < jsonData.length; i++) {
        serialNumbers += jsonData[i]['Serial No. '];
        if (i < jsonData.length - 1) serialNumbers += '\n';
      }
      setSerialNumbers(serialNumbers);
    }
    const tagLifeIndex = columnHeaders.indexOf('Est tag life (days)');
    if (tagLifeIndex >= 0) {
      let tagLife = '';
      for (let i = 0; i < jsonData.length; i++) {
        tagLife += jsonData[i]['Est tag life (days)'];
        if (i < jsonData.length - 1) tagTypes += '\n';
      }
      setTagLife(tagLife);
    }
    setSheetNames(workbook.SheetNames);
    setSheets(workbook);
    setActiveSheetIndex(sheetIndex);
    setHeaders(columnHeaders);
    setSelectedColumnIndex(defaultColumnIndex);
    setTagIds(columnValues);
    setIsEmptySheet(false);
  }

  function updateColumnData(columnIndex) {
    const activeSheet = sheets.Sheets[sheets.SheetNames[activeSheetIndex]];
    const jsonData = utils.sheet_to_json(activeSheet, { defval: '' });
    const columnValues = extractColumnValues(jsonData, columnIndex);

    setSelectedColumnIndex(columnIndex);
    setTagIds(columnValues);
  }

  function resetSheetState() {
    setSheetNames([]);
    setSheets([]);
    setActiveSheetIndex(null);
    setSelectedColumnIndex(null);
    setHeaders([]);
    setIsEmptySheet(true);
  }

  return (
    <>
      <p>Please provide a CSV or Excel file (.xls, .xlsx).</p>
      <CustomFileInput
        handleFileChange={handleFileChange}
        acceptedTypes=".xls,.xlsx,.csv"
      />
      {isEmptySheet && <p>The file you have selected has no data.</p>}
      {sheetNames.length > 0 && (
        <Form>
          <div className="content-divider">
            <h5>Select a sheet name.</h5>
            {sheetNames.map((sheetName, index) => (
              <div key={sheetName}>
                <input
                  type="radio"
                  value={index}
                  checked={index === activeSheetIndex}
                  onChange={handleSheetChange}
                />
                <label>&nbsp;{sheetName}</label>
              </div>
            ))}
            <p className="content-divider">
              Please choose the column that holds the codespace + tag ID value,
              e.g., <b>A69-9002-12345</b>.
            </p>
            <select
              value={selectedColumnIndex || ''}
              onChange={handleColumnChange}
            >
              {headers.map((header, index) => (
                <option key={header} value={index}>
                  {header}
                </option>
              ))}
            </select>
            <OceanRegionsSelector
              regionsValue={regionsValue}
              handleSelectChange={regionSelectHandler}
              selectAllRegionsHandler={selectAllRegionsHandler}
              tagSearchHandler={() => tagSearchHandler(tagIds, regionsValue)}
              buttonDisableClause={tagIds.length < 1 || regionsValue.length < 1}
            />
          </div>
        </Form>
      )}
    </>
  );
}
