import { useRef, useEffect } from 'react';
import OceanRegionsSelector from './OceanRegionsSelector';
import { Form, TextArea } from 'semantic-ui-react';
import '../styles/textStyles.less';

export function ManualTagForm({
  regionsValue,
  regionSelectHandler,
  selectAllRegionsHandler,
  setTagIds,
  tagIds,
  tagSearchHandler,
}) {
  const inputRef = useRef();

  const handleTextChange = (event) => {
    setTagIds(event.target.value);
  };

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <>
      <Form>
        <p>
          Manually enter codespace + tag_id. Please separate entries by line.
        </p>
        <textarea
          ref={inputRef}
          id="tag_ids"
          className="text-area-border"
          placeholder="E.g. A69-9002-12345"
          value={tagIds}
          onChange={handleTextChange}
        ></textarea>
        <br />
        <OceanRegionsSelector
          regionsValue={regionsValue}
          handleSelectChange={regionSelectHandler}
          selectAllRegionsHandler={selectAllRegionsHandler}
          tagSearchHandler={() => tagSearchHandler(tagIds, regionsValue)}
          buttonDisableClause={tagIds.length < 1 || regionsValue.length < 1}
        />
      </Form>
    </>
  );
}
