import { SEARCH_MYSTERY_TAGS } from "./ActionTypes";

export function mysteryTags(state = {}, action = {}) {
  let { result, url } = action;

  switch (action.type) {
    case `${SEARCH_MYSTERY_TAGS}_PENDING`:
      return {
        ...state,
        [url]: {
          ...state[url],
          loading: true,
          loaded: false,
          error: undefined,
        },
      };
    case `${SEARCH_MYSTERY_TAGS}_SUCCESS`:
      return {
        ...state,
        [url]: {
          ...state[url],
          loading: false,
          loaded: true,
          error: undefined,
          data: result,
        },
      };
    case `${SEARCH_MYSTERY_TAGS}_FAIL`:
      return {
        ...state,
        [url]: {
          ...state[url],
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    default:
      break;
  }
  return state;
}

