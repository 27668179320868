import MysterytagsView from './mysterytags/MysterytagsView';
import folderSVG from '@plone/volto/icons/table.svg';
import  {mysteryTags } from './reducers'

const applyConfig = (config) => {
  config.addonReducers.mysteryTags = mysteryTags;
  config.blocks.blocksConfig.mysterytags = {
    id: 'mysterytags',
    title: 'Mystery Tags',
    icon: folderSVG,
    group: 'common',
    view: MysterytagsView,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  return config;
};

export default applyConfig;
