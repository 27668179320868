import { useState } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { TagResultsTabel } from './TagResultsTable';
import { utils, writeFile } from 'xlsx';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { downloadTaggingMetadataSheet } from '../actions';

export default function MysteryTagSearchResults({
  queryResults,
  searchAgainHandler,
  setTaggerNameHandler,
  setTagOwnerPIHandler,
  taggerName,
  tagOwnerPI,
  setTagOwnerOrgHandler,
  tagOwnerOrg,
  serialNumber,
  estTagLife,
  tagType,
  tagIds,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [tagView, setTagView] = useState(true);
  const [commonName, setCommonName] = useState('');
  const [scientificName, setScientificName] = useState('');

  const downloadMysteryTags = useCallback(() => {
    /* generate worksheet from state */
    const date = new Date();
    const ws = utils.json_to_sheet(queryResults);
    /* create workbook and append worksheet */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');
    /* export to XLSX */
    writeFile(
      wb,
      `mystery_tag_${date.getFullYear()}-${date.getMonth() + 1
      }-${date.getDate()}.csv`,
    );
  }, [queryResults]);

  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  function downloadTagMetaDataSheet(e) {
    e.preventDefault();
    dispatch(
      downloadTaggingMetadataSheet(location.pathname, {
        tagger_name: taggerName,
        tag_owner_pi: tagOwnerPI,
        tag_owner_organization: tagOwnerOrg,
        common_name: commonName,
        scientific_name: scientificName,
        tag_ids: tagIds,
        serial_numbers: serialNumber,
        est_tag_life: estTagLife,
        tag_types: tagType,
      }),
    )
      .then((response) => {
        const blob = new Blob([s2ab(atob(response))], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(blob);

        const date = new Date();
        // Create a temporary <a> element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `tagging_metadata_sheet_${date.getFullYear()}-${date.getMonth() + 1
          }-${date.getDate()}.xlsx`,
        );
        document.body.appendChild(link);
        link.click();

        // Clean up
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error downloading the file:', error);
      });
  }
  if (queryResults.error) {
    return (
      <>
        <h1>Sorry an unexpected error has occurred.</h1>
        <p>
          Try to refresh the page or feel free to contact us if the problem
          persists.
        </p>
      </>
    );
  }
  return (
    <>
      <Form>
        <h1>Mystery Tag Results</h1>
        <TagResultsTabel
          queryResults={queryResults}
          tagViewToggleHandler={() => setTagView(!tagView)}
          tagView={tagView}
        />
        {queryResults.length > 0 ? (
          <Button
            download="mystery_tag.csv"
            class="context"
            onClick={downloadMysteryTags}
          >
            <span class="icon-document"></span> Download Mystery Tag CSV
          </Button>
        ) : (
          ''
        )}
        <hr />
        <Button onClick={searchAgainHandler}>Search Again?</Button>
        <br />
        <h1>Create Tagging Metadata</h1>
        <p>
          Please fill out the following form below to create a tagging metadata
          sheet. The resulting sheet will include the tag IDs that you provided
          in the previous step.
        </p>
        <form onSubmit={(e) => downloadTagMetaDataSheet(e)}>
          <div className="form-input">
            <label>
              <h3>Tagger Name</h3>
            </label>
            <div>
              <p className="hint-text">
                First and last name of person conducting tagging.
              </p>
            </div>
            <input
              type="text"
              name="tagger_name"
              className="input-text"
              value={taggerName}
              onChange={(e) => setTaggerNameHandler(e.target.value)}
            />
          </div>
          <div className="form-input">
            <label>
              <h3>Tag Owner PI</h3>
            </label>
            <div>
              <p className="hint-text">
                First and last name of the Principal Investigator for the
                project.
              </p>
            </div>
            <input
              type="text"
              name="tag_owner_pi"
              className="input-text"
              value={tagOwnerPI}
              onChange={(e) => setTagOwnerPIHandler(e.target.value)}
            />
          </div>
          <div className="form-input">
            <label>
              <h3>Tag Owner Organization</h3>
            </label>
            <div>
              <p className="hint-text">
                Affiliation of the Principal Investigator for the project.
              </p>
            </div>
            <input
              type="text"
              name="tag_owner_organization"
              className="input-text"
              value={tagOwnerOrg}
              onChange={(e) => setTagOwnerOrgHandler(e.target.value)}
            />
          </div>
          <div className="form-input">
            <label>
              <h3>Common Name (English)</h3>
            </label>
            <div>
              <p className="hint-text">
                Common name of animal that carries the tag in English.
              </p>
            </div>
            <input
              type="text"
              name="common_name"
              className="input-text"
              value={commonName}
              onChange={(e) => setCommonName(e.target.value)}
            />
          </div>
          <div className="form-input">
            <label>
              <h3>Scientific Name</h3>
            </label>
            <div>
              <p className="hint-text">
                Scientific name of animal that carries the tag.
              </p>
            </div>
            <input
              type="text"
              className="input-text"
              name="scientific_name"
              value={scientificName}
              onChange={(e) => setScientificName(e.target.value)}
            />
          </div>
          <input type="hidden" name="tag_ids" value="12345" />
          <input type="hidden" name="serial_numbers" />
          <input type="hidden" name="est_tag_life" />
          <input type="hidden" name="tag_types" />
          <div className="button-div">
            <Button onClick={(e) => downloadTagMetaDataSheet(e)}>
              <span class="icon-document"></span> Download Metadata (XLSX)
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
}
