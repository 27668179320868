/**
 * Utiltily function for selecting all ocean regions as the state
 * for the OceanRegionSelector component.
 * @param {number} regionsLength - The number of regions the current state has
 * @param {function} setRegions - The function to set the region state
 */
export default function selectAllRegions(regionsLength, setRegions) {
  if (regionsLength < 16) {
    setRegions(["ARCTIC", "E INDIAN", "GLOBAL", "GREAT LAKES", "MEDITERRANEAN", "MID PACIFIC", "NE ATLANTIC", "NE PACIFIC", "NW ATLANTIC", "NW PACIFIC", "SE ATLANTIC", "SE PACIFIC", "SOUTHERN", "SW ATLANTIC", "SW PACIFIC", "W INDIAN"]);
    return;
  }
  setRegions([]);
}

export const handleSelect = (event, setRegions) => {
  const options = [...event.target.selectedOptions];
  const values = options.map((option) => option.value);
  setRegions(values);
};
