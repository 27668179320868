import { Button } from 'semantic-ui-react';

export default function OceanRegionsSelector({
  regionsValue,
  handleSelectChange,
  selectAllRegionsHandler,
  tagSearchHandler,
  buttonDisableClause,
}) {
  return (
    <div className="content-divider">
      <p>
        <b>Ocean Regions</b>: Limit the mystery tag search to the ocean regions
        where the tag was detected.
      </p>
      <select
        multiple={true}
        id="#regions"
        style={{ height: '20em' }}
        value={regionsValue}
        onChange={handleSelectChange}
      >
        <option value="ARCTIC">ARCTIC</option>
        <option value="E INDIAN">E INDIAN</option>
        <option value="GLOBAL">GLOBAL</option>
        <option value="GREAT LAKES">GREAT LAKES</option>
        <option value="MEDITERRANEAN">MEDITERRANEAN</option>
        <option value="MID PACIFIC">MID PACIFIC</option>
        <option value="NE ATLANTIC">NE ATLANTIC</option>
        <option value="NE PACIFIC">NE PACIFIC</option>
        <option value="NW ATLANTIC">NW ATLANTIC</option>
        <option value="NW PACIFIC">NW PACIFIC</option>
        <option value="SE ATLANTIC">SE ATLANTIC</option>
        <option value="SE PACIFIC">SE PACIFIC</option>
        <option value="SOUTHERN">SOUTHERN</option>
        <option value="SW ATLANTIC">SW ATLANTIC</option>
        <option value="SW PACIFIC">SW PACIFIC</option>
        <option value="W INDIAN">W INDIAN</option>
      </select>
      <br />
      <br />
      <input
        type="checkbox"
        onChange={selectAllRegionsHandler}
        checked={regionsValue.length === 16}
      />
      <label>&nbsp;Select All Regions</label>
      <hr />
      <Button disabled={buttonDisableClause} onClick={tagSearchHandler}>
        Search for Tags
      </Button>
    </div>
  );
}
