import {
  Button,
  Table,
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
} from 'semantic-ui-react';
import '../styles/textStyles.less';
export function TagResultsTabel({
  queryResults,
  tagViewToggleHandler,
  tagView,
}) {
  if (queryResults.length === 0) {
    return <p>Sorry, no mystery tag records found for your current search.</p>;
  }
  return (
    <>
      <h3>
        Found {queryResults.length} Tag
        {queryResults.length === 1 ? ' Result' : ' Results'}
      </h3>
      <Button onClick={tagViewToggleHandler}>Toggle Tag View</Button>
      {tagView ? (
        <Table celled padded>
          <TableHeader>
            <TableRow>
              <TableHeaderCell className="black-text">Index</TableHeaderCell>
              <TableHeaderCell className="black-text">Tag ID</TableHeaderCell>
              <TableHeaderCell className="black-text">
                Year Collected
              </TableHeaderCell>
              <TableHeaderCell className="black-text">
                Detection Count
              </TableHeaderCell>
              <TableHeaderCell className="black-text">
                Ocean Region
              </TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {queryResults.map((val, idx) => (
              <TableRow>
                <TableCell>{idx}</TableCell>
                <TableCell>{val.tag}</TableCell>
                <TableCell>{val.yearcollected}</TableCell>
                <TableCell>{val.detection_count}</TableCell>
                <TableCell>{val.region}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        ''
      )}
    </>
  );
}
