import React, { useState } from 'react';
import loadable from '@loadable/component';
import { Statistic, Message } from 'semantic-ui-react'
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getHeaderStatistics } from '../actions/Statistics/stats';
import numeral from 'numeral';
import '../style/statistics_header.less';

const StatsHeader = (props) => {
    const { data } = props;
    const [stats, setStats] = useState([]); // Projects populated by search 
    const [showError, setShowError] = useState(false); // Shows message if fetch fails
    const location = useLocation();
    const dispatch = useDispatch();

    async function getHeaderStats(){
        dispatch(getHeaderStatistics(location.pathname)).then(
          result => setStats(result)
        ).catch(function (err) {
          console.info(err)
          setShowError(true);
        });
    };

    React.useEffect(() => {
        getHeaderStats();
    }, []);
    return (

    <div className="statistics_group">
    {showError &&
<Message  color='red' style={{display: "flex","alignItems": "center",padding:"1em 1em"}}>
<svg
   style={{"scale":"67%"}}
   version="1.1"
   id="svg1"
   width="42"
   height="42"><defs
     id="defs1" /><g
     id="g1"
     transform="matrix(3.7795276,0,0,3.7795276,-189.99999,-355.00001)"><ellipse
       style={{"fill":"#e50d69","fillOpacity":1,"stroke":"none","strokeWidth":0.1, "strokeLinecap":"round"}}
       id="path6"
       cx="55.562496"
       cy="99.21875"
       rx="5.2916665"
       ry="5.2916641" /><path
       id="path8"
       style={{fill:"none", "fillOpacity":1, "stroke":"#ffffff", "strokeWidth":1.7,
       "strokeLinecap":"round","strokeDasharray":"none"}}
       d="m 57.741697,97.039539 -4.358406,4.358401 m -2e-6,-4.358401 4.35841,4.358401" /></g></svg>
    Error retrieving header statistics
    </Message>
    }
        <Statistic.Group widths='four'>
            <Statistic className="stat_divider otn_dark_blue">
                <Statistic.Value>{numeral(stats.project_count).format('0,0')}</Statistic.Value>
                <Statistic.Label><a href="/data/discovery/bycollection.htm">Projects</a></Statistic.Label>
            </Statistic>
            <Statistic className="stat_divider otn_light_blue">
                <Statistic.Value>{numeral(stats.contributor_count).format('0,0')}</Statistic.Value>
                <Statistic.Label><a href="/data/discovery/bycontact.htm">Contributors</a></Statistic.Label>
            </Statistic>
            <Statistic className="stat_divider otn_green">
                <Statistic.Value>{numeral(stats.inst_count).format('0,0')}</Statistic.Value>
                <Statistic.Label><a href="/data/discovery/byinstitution.htm">Institutions</a></Statistic.Label>
            </Statistic>
            <Statistic className="otn_yellow">
                <Statistic.Value>{numeral(stats.species_count).format('0,0')}</Statistic.Value>
                <Statistic.Label><a href="/data/discovery/byspecies.htm">Species</a></Statistic.Label>
            </Statistic>
        </Statistic.Group>
    </div>
    );
};

export default StatsHeader
