import { SEARCH_MYSTERY_TAGS, DOWNLOAD_TAGGING_SHEET } from './ActionTypes';
export function searchMysteryTags(url, data) {
  return {
    type: SEARCH_MYSTERY_TAGS,
    request: {
      op: 'post',
      path: `${url}/mystery_tag_search_tags`,
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    },
  };
}
export function downloadTaggingMetadataSheet(url, data) {
  return {
    type: DOWNLOAD_TAGGING_SHEET,
    request: {
      op: 'post',
      path: `${url}/mystery_tag_create_sheet`,
      headers: {
        'Content-Type': 'application/json',
        Accept:
          'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7',
      },
      data,
    },
  };
}
