import React, { useState } from 'react';
import MysteryTagDataEntry from '../components/MysteryTagDataEntry';
import useTagSearch from '../hooks/useTagSearch';
import selectAllRegions, { handleSelect } from '../utils/selectAllOceanRegions';
import MysteryTagSearchResults from '../components/MysteryTagSearchResults';

const MysterytagsView = () => {
  const [regions, setRegions] = useState([]);
  const [tagIds, setTagIds] = useState('');
  const { data, setData, searchTags } = useTagSearch();
  const [useFile, setUseFile] = useState(true);
  const [taggerName, setTaggerName] = useState('');
  const [tagOwnerPI, setTagOwnerPI] = useState('');
  const [tagOwnerOrganization, setTagOwnerOrganization] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [estTagLife, setEstTagLife] = useState('');
  const [tagType, setTagType] = useState('');

  if (data === null) {
    return (
      <>
        <MysteryTagDataEntry
          regionsValue={regions}
          selectAllRegionsHandler={() =>
            selectAllRegions(regions.length, setRegions)
          }
          regionSelectHandler={(e) => handleSelect(e, setRegions)}
          setTagIds={setTagIds}
          tagIds={tagIds}
          tagSearchHandler={() => searchTags(tagIds, regions)}
          useFile={useFile}
          setUseFile={setUseFile}
          setTaggerNameHandler={setTaggerName}
          setTagOwnerPIHandler={setTagOwnerPI}
          setTagOwnerOrgHandler={setTagOwnerOrganization}
          setTagTypeHandler={setTagType}
          setSerialNumbers={setSerialNumber}
          setTagLife={setEstTagLife}
        />
      </>
    );
  }

  return (
    <MysteryTagSearchResults
      queryResults={data}
      searchAgainHandler={() => setData(null)}
      setTaggerNameHandler={setTaggerName}
      setTagOwnerPIHandler={setTagOwnerPI}
      taggerName={taggerName}
      tagOwnerPI={tagOwnerPI}
      setTagOwnerOrgHandler={setTagOwnerOrganization}
      tagOwnerOrg={tagOwnerOrganization}
      setTagTypeHandler={setTagType}
      setSerialNumbers={setSerialNumber}
      setTagLife={setEstTagLife}
      serialNumber={serialNumber}
      estTagLife={estTagLife}
      tagType={tagType}
      tagIds={tagIds}
    />
  );
};

export default MysterytagsView;
