import { ManualTagForm } from './ManualTagForm.jsx';
import { FileSelector } from './FileSelector.jsx';

function MysteryTagDataEntry({
  regionsValue,
  selectAllRegionsHandler,
  regionSelectHandler,
  setTagIds,
  tagIds,
  tagSearchHandler,
  useFile,
  setUseFile,
  setTaggerNameHandler,
  setTagOwnerPIHandler,
  setTagOwnerOrgHandler,
  setTagTypeHandler,
  setSerialNumbers,
  setTagLife,
}) {
  return (
    <>
      <p>
        Using this tool you can search the OTN database for tags classified as
        mystery tags, tags that were unresolved across the telemetry projects
        currently in the OTN database. You supply a list of your project's
        deployed tags, and this tool will provide you with:
      </p>
      <ul>
        <li>A list of matches from OTN mystery tags</li>
        <li>Years in which the tag was detected</li>
        <li>The total number of times each tag was detected</li>
        <li>The general ocean regions where the tag was detected.</li>
      </ul>
      <p>
        Tags entered here can be exported into the OTN tag metadata format to
        streamline the process of registering your tags as part of the Ocean
        Tracking Network and retrieving all detections, past, present and
        future, of your deployed tags across all OTN-affiliated receivers
        worldwide. If the supplied tag IDs have already been assigned to a
        researcher, with OTN, for the ocean and year in question, it will not be
        returned by this tool.
      </p>
      <p>
        <strong>Note:</strong> Recently released tags may not appear using this
        tool. This is due to the frequency in which receiver data has been
        loaded in our system.
      </p>
      <input
        type="checkbox"
        name="useFile"
        id="useFile"
        checked={useFile}
        onChange={(e) => setUseFile(e.target.checked)}
      />
      <label>&nbsp;Use File?</label>
      <br />
      <br />
      {useFile ? (
        <FileSelector
          regionsValue={regionsValue}
          selectAllRegionsHandler={selectAllRegionsHandler}
          regionSelectHandler={regionSelectHandler}
          setTagIds={setTagIds}
          tagIds={tagIds}
          tagSearchHandler={tagSearchHandler}
          setTaggerNameHandler={setTaggerNameHandler}
          setTagOwnerPIHandler={setTagOwnerPIHandler}
          setTagOwnerOrgHandler={setTagOwnerOrgHandler}
          setTagTypeHandler={setTagTypeHandler}
          setSerialNumbers={setSerialNumbers}
          setTagLife={setTagLife}
        />
      ) : (
        <ManualTagForm
          regionsValue={regionsValue}
          selectAllRegionsHandler={selectAllRegionsHandler}
          regionSelectHandler={regionSelectHandler}
          setTagIds={setTagIds}
          tagIds={tagIds}
          tagSearchHandler={tagSearchHandler}
        />
      )}
    </>
  );
}

export default MysteryTagDataEntry;
