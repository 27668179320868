import { useRef, useState } from 'react';
import { Button, Label, Icon } from 'semantic-ui-react';

export default function CustomFileInput({ handleFileChange, acceptedTypes }) {
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState('Select a File');
  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const internalHandleFileChange = (event) => {
    const file = event.target.files[0];
    setFileName(file ? file.name : 'Select a File');
    handleFileChange(event);
  };

  return (
    <div>
      <Button as="div" labelPosition="right" onClick={handleFileClick}>
        <Button icon>
          <Icon name="upload" />
          Upload
        </Button>
        <Label basic>{fileName}</Label>
      </Button>
      <input
        type="file"
        accept={acceptedTypes}
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={(e) => internalHandleFileChange(e)}
      />
    </div>
  );
}
